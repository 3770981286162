import React from "react"
import { graphql } from "gatsby"
import HeaderBar from "./../HeaderBar"
import MasterLayout from "./master"
import { defineCustomElements as deckDeckGoHighlightElement } from "@deckdeckgo/highlight-code/dist/loader";
deckDeckGoHighlightElement();

class InsetPageLayout extends React.Component {
  render() {
    const siteMetadata = this.props.data.site
    console.log(`insetPage ::: ${siteMetadata}`)

    return (
      <div className="PageTemplate">
        <MasterLayout {...this.props}>
            <div>
              <div className="header-container">
                <div className="container">                
                  <HeaderBar {...this.props} />
                </div>
              </div>
              <div className="container">
                  <div className="section" style={{margin: "0 auto"}}>{this.props.children}</div>
                </div>
            </div>
        </MasterLayout>
      </div>
    )
  }
}

export default InsetPageLayout

export const pageQuery = graphql`
  query InsetLayoutBySlug {
    site {
      siteMetadata {
        title
        siteDescr
        siteAuthor
        siteEmailUrl
        siteEmailPretty
        siteTwitterUrl
        siteTwitterPretty
      }
    }
  }
`
