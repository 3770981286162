import React from "react"
import { Link, graphql } from "gatsby"
import sortBy from "lodash/sortBy"
import moment from "moment"
import Img from "gatsby-image"
import InsetPageLayout from "../components/Layouts/insetPage"
import './Index.css'
import { defineCustomElements as deckDeckGoHighlightElement } from "@deckdeckgo/highlight-code/dist/loader";
deckDeckGoHighlightElement();


export const frontmatter = {
  layoutType: `page`,
  path: `/`,
}

const Topiclist = (frontmatter, iteratorKey) => {
  let thumbnail = frontmatter.image;
  // if(frontmatter && frontmatter.image) {
  //   console.log(`${frontmatter.title}, thumbnail: ${thumbnail.childImageSharp.responsiveSizes.src}`);
  //   thumbnail = thumbnail.childImageSharp.responsiveSizes;
  // } else {
  //   thumbnail = 'https://bulma.io/images/placeholders/640x320.png';
  // }
  
  return (
    <div className="border-toc" key={iteratorKey}>
    <article className="media">
      <div className="media-content toc-height">
        <div className="columns" style={{overflowX: "hidden", overflowY: "scroll", maxWidth: 1024, margin: "0 auto"}}>
          <div className="column is-one-fifth">
            <div>
              <div className="">
                <h1
                  className="subtitle is-6"                  
                  dateTime={moment(
                    frontmatter.updated || frontmatter.written
                  ).format(`MMMM D, YYYY`)}
                >
                  {moment(
                    frontmatter.updated || frontmatter.written
                  ).format(`MMMM D, YYYY`)}
                </h1>
                <h5 className="tag">{frontmatter.category}</h5>      
              </div>   
            </div>                   
          </div>
          <div className="column is-three-fifth">
            <div className="heading">
              <h2 className="title is-marginless is-4 is-size-7-mobile">
                <Link className="default-link" to={frontmatter.path}>{frontmatter.title}</Link>
              </h2>
            </div>
              <p
                className="desc-message"
                dangerouslySetInnerHTML={{
                  __html: frontmatter.description,
                }}
              />                  
              <Link className="default-link" to={frontmatter.path}>Read</Link>
          </div>
          <div className="column is-one-fifth desc-thumb">
            {/* <figure class="image is-3by2"> */}
              <Img fixed={thumbnail.childImageSharp.fixed} />
              {/* <Img src={thumbnail} /> */}              
              {/* <img src={thumbnail.src} /> */}
              {/* <img src="https://bulma.io/images/placeholders/640x320.png" /> */}
            {/* </figure>             */}
                {/* <img height="100px" src="static/theflash-92d63e09de20b9ef5789f462065464f7-b5b8e.jpg" /> */}
          </div>
        </div>
      </div>
    </article>
  </div>      
  )
}

class SiteIndex extends React.Component {
  
  render() {
    const pageLinks = []
    let iteratorKey = 0
    let pageRaw = [
      ...this.props.data.allMarkdownRemark.edges,
      ...this.props.data.allJavascriptFrontmatter.edges,
      // ...this.props.data.allMdx.edges
    ]
    let pageArray = []
    pageRaw.forEach(page => {
      if (typeof page.node.frontmatter === `object`) {
        if (typeof page.node.frontmatter.written !== `undefined`) {
          pageArray.push(page.node.frontmatter)
        }
      }
    })

    const sortedPages = sortBy(
      pageArray,
      page => page.updated || page.written
    ).reverse()
    sortedPages.forEach(page => {
      let frontmatter = page

      if (frontmatter.layoutType === `post`) {
        iteratorKey += 1
        pageLinks.push(Topiclist(frontmatter, iteratorKey));
        // pageLinks.push(
        //   <div className="box" key={iteratorKey}>
        //     <article className="media">
        //       <div className="media-content">
        //         <div className="heading">
        //           <div className="level">
        //             <h4 className="level-left">
        //               <time
        //                 className="subtitle"
        //                 dateTime={moment(
        //                   frontmatter.updated || frontmatter.written
        //                 ).format(`MMMM D, YYYY`)}
        //               >
        //                 {moment(
        //                   frontmatter.updated || frontmatter.written
        //                 ).format(`MMMM YYYY`)}
        //               </time>
        //             </h4>
        //             <h5 className="level-right">{frontmatter.category}</h5>
        //           </div>
        //           <h1 className="title is-marginless">
        //             <Link to={frontmatter.path}>{frontmatter.title}</Link>
        //           </h1>
        //         </div>
        //         <div className="content">
        //           <p
        //             dangerouslySetInnerHTML={{
        //               __html: frontmatter.description,
        //             }}
        //           />
        //         </div>
        //         <nav className="level">
        //           <div className="level-left">
        //             <span className="level-item">
        //               <Link to={frontmatter.path}>Read</Link>
        //             </span>
        //           </div>
        //         </nav>
        //       </div>
        //     </article>
        //   </div>
        // )
      }
    })

    return <InsetPageLayout {...this.props}>{pageLinks}</InsetPageLayout>
  }
}

export default SiteIndex

export const pageQuery = graphql`
  query {
    allJavascriptFrontmatter(filter: {frontmatter: {contentType: {eq: "blog"}}}) {
      edges {
        node {
          fileAbsolutePath
          frontmatter {
            path
            title
            written
            layoutType
            contentType
            category
            description
            updated
            image {
              childImageSharp {
                fixed(width: 180) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
      }
    }
    allMarkdownRemark(filter: {frontmatter: {contentType: {eq: "blog"}}}) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            path
            layoutType
            contentType
            written
            updated
            category
            description
            image {
              childImageSharp {
                fixed(width: 180) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
          timeToRead
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }    
  }
`